<template>
  <b-container
    class="mb-5"
    fluid
  >

    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_category"
            >
              <strong>
                Category
              </strong>
            </label>
            <b-select
              id="filter_category"
              v-model="filter.category"
              :options="filterCategories"
              :disabled="state.busy || fetching.filter.categories"
              @change="onRefreshTableTemplatedItems"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="filter_search">
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableTemplatedItems.filter"
              type="text"
              placeholder="enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="primary"
            @click="onShowAddFormTemplatedItem"
          >
            Add Templated Item
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableTemplatedItems"
        small
        hover
        responsive
        class="mt-2"
        :per-page="tableTemplatedItems.perPage"
        :current-page="tableTemplatedItems.currentPage"
        :items="templatedItemProvider"
        :fields="tableTemplatedItems.fields"
        :sort-by.sync="tableTemplatedItems.sortBy"
        :sort-desc.sync="tableTemplatedItems.sortDesc"
        :sort-direction="tableTemplatedItems.sortDirection"
        :filter="tableTemplatedItems.filter"
        :filter-included-fields="tableTemplatedItems.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableTemplatedItems.currentPage * tableTemplatedItems.perPage -
              tableTemplatedItems.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell(item_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(employee_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onItemShowEdit(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableTemplatedItems.perPage"
              :options="tableTemplatedItems.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableTemplatedItems.currentPage"
            :total-rows="tableTemplatedItems.totalRows"
            :per-page="tableTemplatedItems.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-templated-item"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="modalTemplatedItemTitle"
      @ok="onValidateFormTemplatedItem"
    >
      <ValidationObserver
        ref="formTemplatedItems"
      >
        <form
          role="formTemplatedItems"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                v-if="state.editing"
              >
                <label for="template">
                  <strong>Template</strong>
                </label>
                <b-input
                  id="template"
                  v-model="templatedItem.template_name"
                  type="text"
                  disabled
                />
              </b-form-group>
              <ValidationProvider
                v-else
                #default="{ errors }"
                name="template"
                vid="template"
                rules="required"
              >
                <b-form-group>
                  <label for="template">
                    <strong>Template</strong>
                  </label>
                  <b-form-select
                    id="template"
                    v-model="templatedItem"
                    :state="errors.length > 0 ? false : null"
                    :options="list.templates"
                    :disabled="fetching.templates"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="{ template: null, use_file_input: 0, template_name: null, compositions: [] }"
                        disabled
                      >
                        -- select template --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="!state.editing && templatedItem.use_file_input === 1"
            >
              <b-form-group>
                <label for="computer_file">
                  <strong>JSON File</strong>
                </label>
                <b-form-file
                  id="computer_file"
                  v-model="attachment.file"
                  accept=".json"
                  :disabled="state.busy"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="global_purchase_date">
                      <strong>Purchase Date</strong>
                    </label>
                    <b-input
                      id="global_purchase_date"
                      v-model="global.purchase_date"
                      type="date"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="total_original_cost">
                      <strong>Total Cost</strong>
                    </label>
                    <b-input
                      id="total_original_cost"
                      v-model="totalCosts"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="supplier"
                    vid="supplier"
                  >
                    <b-form-group>
                      <label for="global_supplier">
                        <strong>
                          Supplier
                        </strong>
                      </label>
                      <b-input-group class="col-12 px-0">
                        <v-select
                          id="global_supplier"
                          v-model="global.supplier"
                          type="text"
                          label="supplier_name"
                          placeholder="search supplier here"
                          :options="list.suppliers"
                          :class="`flex-grow-1 ${[errors.length > 0 ? 'is-invalid' : null]}`"
                          :disabled="state.busy || fetching.suppliers"
                          :loading="fetching.suppliers"
                        >
                          <template #option="{ supplier_name, supplier_address }">
                            <div class="d-flex flex-column">
                              <strong>{{ supplier_name }}</strong>
                              <span>{{ supplier_address }}</span>
                            </div>
                          </template>
                          <template #no-options="">
                            no available supplier
                          </template>
                        </v-select>
                        <b-input-group-append>
                          <b-button
                            variant="outline-secondary"
                            class="rounded-1 pl-3"
                            :disabled="fetching.suppliers"
                            @click="getSuppliers"
                          >
                            <i class="ri-restart-line h5" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="brand"
                    vid="brand"
                  >
                    <b-form-group>
                      <label for="global_brand">
                        <strong>
                          Brand
                        </strong>
                      </label>

                      <b-input-group class="col-12 px-0">
                        <v-select
                          id="global_brand"
                          v-model="global.brand"
                          type="text"
                          label="brand_name"
                          placeholder="search brand here"
                          :class="`flex-grow-1 ${[errors.length > 0 ? 'is-invalid' : null]}`"
                          :options="list.brands"
                          :disabled="state.busy || fetching.brands"
                          :loading="fetching.brands"
                        >
                          <template #option="{ brand_name }">
                            <div class="d-flex flex-column">
                              <strong>{{ brand_name }}</strong>
                            </div>
                          </template>
                          <template #no-options="">
                            no available brand
                          </template>
                        </v-select>
                        <b-input-group-append>
                          <b-button
                            variant="outline-secondary"
                            class="rounded-1 pl-3"
                            :disabled="fetching.brands"
                            @click="getBrands"
                          >
                            <i class="ri-restart-line h5" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>

            <b-col
              cols="12"
            >
              <b-overlay
                :show="state.busy"
                rounded
                opacity="1"
                spinner-variant="primary"
              >
                <div
                  v-for="(composition, key) in templatedItem.compositions"
                  :key="key"
                >
                  <Item
                    v-if="composition.is_parent === 1"
                    :key="`item-${key}`"
                    :template="templatedItem.template"
                    :position="key"
                    :global="global"
                    :state="state"
                    :fetching="fetching"
                    :suppliers="list.suppliers"
                    :brands="list.brands"
                    :composition="composition"
                    @updated="onItemUpdated"
                  />
                  <ItemDetail
                    v-else
                    :key="`item-detail-${key}`"
                    :position="key"
                    :global="global"
                    :state="state"
                    :fetching="fetching"
                    :suppliers="list.suppliers"
                    :brands="list.brands"
                    :composition="composition"
                    @updated="onItemDetailUpdated"
                    @onRemoveComposition="onRemoveComposition"
                  />
                </div>
              </b-overlay>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-button
              v-if="!state.editing"
              variant="success"
              :disabled="state.busy"
              @click="ok()"
            >
              {{ state.editing ? "Update Record" : "Save Record" }}
            </b-button>
          </div>
          <div>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close Window
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminTemplatedService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import Item from '../components/Item'
import ItemDetail from '../components/ItemDetail'

export default {
  name: 'AdminTemplatedItems',
  middleware: ['auth', 'admin'],
  metaInfo () {
    return {
      title: 'Templated Items'
    }
  },
  components: { Item, ItemDetail },
  mixins: [formatter],
  data () {
    return {
      state: {
        busy: false,
        editing: false,
        rendering: false
      },
      fetching: {
        templates: false,
        types: false,
        categories: false,
        suppliers: false,
        brands: false,
        statuses: false,
        employees: false,
        item_details: false,
        filter: {
          categories: false
        }
      },
      filter: {
        category: 'All'
      },
      list: {
        templates: [],
        types: [],
        categories: [],
        suppliers: [],
        brands: [],
        statuses: [],
        employees: [],
        item_details: [],
        filter: {
          categories: []
        }
      },
      global: {
        item: null,
        item_detail: null,
        purchase_date: null,
        supplier: null,
        brand: null
      },
      attachment: {
        file: null
      },
      selected: {
        item: null,
        is_valid: false
      },
      templatedItem: {
        template: null,
        use_file_input: 0,
        template_name: null,
        compositions: []
      },
      tableTemplatedItems: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'item_code' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_name' },
          { key: 'status_name', label: 'status', class: 'text-center' },
          { key: 'employee_name', label: 'employee' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },
  computed: {
    modalTemplatedItemTitle () {
      return this.state.editing ? 'Edit Templated Item' : 'Add Templated Item'
    },

    filterCategories () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.categories)
    },

    totalCosts () {
      let total = 0.000

      if (this.templatedItem.compositions.length > 0) {
        this.templatedItem.compositions.forEach(row => {
          total = +parseFloat(total).toFixed(3) + +parseFloat(row?.original_cost || 0).toFixed(3)
        })
      }

      return `₱ ${this.numberFormatter(total)}`
    }
  },
  watch: {

    'templatedItem' () {
      this.global.status = null
      this.global.purchase_date = null
      this.global.supplier = null
      this.global.brand = null
      this.global.item = null
      this.attachment.file = null
    },

    'attachment.file' () {
      this.readFile()
    }

  },
  mounted () {
    core.index()
    this.getFilterCategories()
  },
  methods: {

    async readFile () {
      if (this.attachment.file) {
        const reader = new FileReader()

        reader.onload = event => {
          try {
            this.selected.is_valid = true

            const { target: { result } } = event

            const { computer, drives, rams, networks } = JSON.parse(result)

            const enclosure = (enclosure, types = ['notebook', 'laptop']) => {
              let existing = null
              if (_.isArray(types)) {
                types.forEach(type => {
                  if (enclosure.type.toLowerCase().indexOf(type.toLowerCase()) >= 0) {
                    existing = true
                  } else {
                    if (existing === null) {
                      existing = false
                    }
                  }
                })
                return existing
              }
              return enclosure.type.toLowerCase().indexOf(types.toLowerCase()) >= 0
            }

            const compositionDrives = this.templatedItem.compositions.filter(
              filter => filter.name.toLowerCase().indexOf('drive') >= 0 && filter.is_required === 1
            )

            if (compositionDrives.length === drives.length) {
              compositionDrives.forEach((drive, key) => {
                const index = this.templatedItem.compositions.findIndex(
                  finder => finder.type === drive.type && finder.category === drive.category && finder.name === drive.name
                )

                if (index >= 0) {
                  const originalDrive = drives[key]
                  this.templatedItem.compositions[index].serial_number = originalDrive.serial_number
                  this.templatedItem.compositions[index].item_detail_name = originalDrive.model
                  this.templatedItem.compositions[index].specifications = `Brand: ${originalDrive.brand}\n`
                  this.templatedItem.compositions[index].specifications += `Drive Location: ${originalDrive.location}\n`
                  this.templatedItem.compositions[index].specifications += `Capacity: ${originalDrive.capacity}\n`

                  if (drive.category_name.toLowerCase().indexOf(originalDrive.media_type?.toLowerCase()) >= 0) {
                    this.templatedItem.compositions[index].specifications += `Media Type: ${originalDrive.media_type}\n`
                    this.templatedItem.compositions[index].specifications += `Bus Type: ${originalDrive.bus_type}`
                  } else {
                    // this.selected.is_valid = false
                    this.$refs.formTemplatedItems.setErrors({
                      [`compositions.${index}.specifications`]: ['The drive does not match the category']
                    })
                  }
                }
              })
            } else {
              this.selected.is_valid = false
              this.$refs.formTemplatedItems.setErrors({
                template: [`Total drives (${drives.length}) from the file does not match this template`]
              })
            }

            const compositionRams = this.templatedItem.compositions.filter(
              filter => filter.category_name.toLowerCase().indexOf('ram') >= 0 && filter.is_required === 1
            )

            if (compositionRams.length === rams.length) {
              compositionRams.forEach((ram, key) => {
                const index = this.templatedItem.compositions.findIndex(
                  finder => finder.type === ram.type && finder.category === ram.category && finder.name === ram.name
                )

                if (index >= 0) {
                  const originalRam = rams[key]
                  this.templatedItem.compositions[index].serial_number = originalRam.serial_number
                  this.templatedItem.compositions[index].item_detail_name = originalRam.name
                  this.templatedItem.compositions[index].specifications = `Brand: ${originalRam.brand}\n`
                  this.templatedItem.compositions[index].specifications += `RAM Location: ${originalRam.location}\n`
                  this.templatedItem.compositions[index].specifications += `Capacity: ${originalRam.capacity}\n`
                  this.templatedItem.compositions[index].specifications += `Speed: ${originalRam.speed}`
                }
              })
            } else {
              this.selected.is_valid = false
              this.$refs.formTemplatedItems.setErrors({
                template: [`Total RAMS (${rams.length}) from the file does not match this template`]
              })
            }

            this.templatedItem.compositions.map(composition => {
              if (composition.is_parent === 1) {
                composition.type = composition.type_id
                composition.category = composition.category_id
                composition.item_name = computer.model

                composition.specifications = `Name: ${computer.name}\n`
                composition.specifications += `Brand: ${computer.brand}\n`
                composition.specifications += `Enclosure Type: ${computer.enclosure.type}, S/N: ${computer.enclosure.serial_number}\n`
                composition.specifications += `Operating System: ${computer.operating_system.name} Ver. ${computer.operating_system.version}\n`
                composition.specifications += `Processor: ${computer.processor.name}, ID: ${computer.processor.id}\n`
                composition.specifications += `Motherboard: ${computer.motherboard.name}, S/N: ${computer.motherboard.serial_number}\n`
                composition.specifications += `BIOS: ${computer.bios.brand} ${computer.bios.name}${networks.length > 0 ? '\n' : ''}`

                if (enclosure(computer.enclosure) && composition.type_name.toLowerCase() === 'laptop') {
                  composition.serial_number = computer.serial_number
                  networks.filter(network => ['local area connection', 'wifi', 'wi-fi'].includes(network.type.toLowerCase())).forEach((network, key, net) => {
                    composition.specifications += `${network.type}: ${network.name} ( ${network.brand} ), MAC: ${network.mac_address}${net.length === (key + 1) ? '' : '\n'}`
                  })
                } else if (enclosure(computer.enclosure, ['desktop']) && composition.type_name.toLowerCase() === 'desktop') {
                  composition.serial_number = computer.motherboard.serial_number
                  networks.forEach((network, key, net) => {
                    composition.specifications += `${network.type}: ${network.name} ( ${network.brand} ), MAC: ${network.mac_address}${net.length === (key + 1) ? '' : '\n'}`
                  })
                } else {
                  this.selected.is_valid = false
                  this.$refs.formTemplatedItems.setErrors({
                    template: ['The type of device on file does not match this template']
                  })
                }
              }

              return composition
            })
          } catch (err) {
            this.selected.is_valid = false
            this.$refs.formTemplatedItems.setErrors({
              template: ['Unable to parse the file']
            })
          }
        }

        reader.readAsText(this.attachment.file)
      }
    },

    async templatedItemProvider (ctx) {
      return await AdminTemplatedService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_category: this.filter.category
        })
      ).then(({ data }) => {
        this.tableTemplatedItems.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterCategories () {
      this.fetching.filter.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories('parent=1').then(({ data }) => {
          this.list.filter.categories = data.map(({ id, category_name }) => ({ value: id, text: category_name }))
          this.fetching.filter.categories = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getTemplates () {
      this.fetching.templates = true
      await SharedListService.getTemplates().then(({ data }) => {
        this.list.templates = data.map(({ id, template_name, use_file_input, compositions }) => ({
          text: template_name,
          value: {
            template: id,
            use_file_input: use_file_input,
            template_name: template_name,
            compositions: compositions.map(composition => {
              composition.id = null
              composition.type = composition.type_id
              composition.category = composition.category_id
              composition.supplier = null
              composition.supplier_name = null
              composition.brand = null
              composition.brand_name = null
              composition.item_name = null
              composition.serial_number = null
              composition.purchase_date = null
              composition.original_cost = 0.00
              composition.specifications = null
              composition.device_name = null
              composition.mac_address = null
              composition.templated = true
              composition.is_required = Number(composition.is_required)
              composition.is_removed = 0
              composition.is_parent = Number(composition.is_parent)
              composition.active = 1

              if (composition.is_parent === 0) {
                composition.item = null
                composition.item_detail_name = null
              }

              return composition
            })
          }
        }))
        this.fetching.templates = false
      })
    },

    async getSuppliers () {
      this.fetching.suppliers = true
      return new Promise(resolve => {
        SharedListService.getSuppliers().then(({ data }) => {
          this.list.suppliers = data.map(({ id, supplier_name, supplier_address }) => ({ id, supplier_name, supplier_address }))
          this.fetching.suppliers = false
          resolve(true)
        })
      })
    },

    async getBrands () {
      this.fetching.brands = true
      return new Promise(resolve => {
        SharedListService.getBrands().then(({ data }) => {
          this.list.brands = data.map(({ id, brand_name }) => ({ id, brand_name }))
          this.fetching.brands = false
          resolve(true)
        })
      })
    },

    onRefreshTableTemplatedItems () {
      this.$refs.tableTemplatedItems.refresh()
    },

    onShowAddFormTemplatedItem () {
      this.state.editing = false

      this.templatedItem.template = null
      this.templatedItem.use_file_input = 0
      this.templatedItem.template_name = null
      this.templatedItem.compositions = []

      this.getTemplates().then(() => {
        this.getSuppliers().then(() => {
          this.getBrands()
        })
      })

      this.$bvModal.show('modal-templated-item')
    },

    async onValidateFormTemplatedItem (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formTemplatedItems.validate().then(async success => {
        if ((success && this.selected.is_valid && this.templatedItem.use_file_input === 1) || (success && this.templatedItem.use_file_input === 0)) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to create items from this template?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              return this.onPostTemplatedItem()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          if (this.selected.is_valid === false) {
            this.$refs.formTemplatedItems.setErrors({
              template: ['The file does not match this template']
            })
          }

          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostTemplatedItem () {
      return new Promise(resolve => {
        AdminTemplatedService.post(this.templatedItem).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-templated-item')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableTemplatedItems.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formTemplatedItems.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onItemShowEdit (item) {
      this.state.editing = true
      this.templatedItem.template = item.template_id
      this.templatedItem.template_name = item.template_name
      this.templatedItem.use_file_input = 0
      this.templatedItem.compositions = []

      this.getSuppliers().then(() => {
        this.getBrands().then(() => {
          const compositions = []
          item.is_required = 1
          compositions.push(this.dataToComposition(item, 1))
          compositions.push(
            ...item.item_details.map(detail => {
              detail.is_required = 1
              return this.dataToComposition(detail)
            })
          )
          this.templatedItem.compositions = compositions.sort(function (a, b) { return b.is_parent - a.is_parent })
        })
      })

      this.$bvModal.show('modal-templated-item')
    },
    // UPDATE ITEM
    onItemUpdated (updated) {
      const item = _.find(this.$refs.tableTemplatedItems.localItems, { id: updated.id })
      if (item) {
        item.item_name = updated.item_name
        item.type_id = updated.type
        item.type_name = updated.type_name
        item.category_id = updated.category
        item.category_name = updated.category_name
        item.supplier_id = updated.supplier
        item.supplier_name = updated.supplier_name
        item.brand_id = updated.brand_id
        item.brand_name = updated.brand_name
        item.serial_number = updated.serial_number
        item.purchase_date = updated.purchase_date
        item.original_cost = updated.original_cost
        item.specifications = updated.specifications
        item.device_name = updated.device_name
        item.mac_address = updated.mac_address
        item.is_parent = 1
        item.active = updated.active
      }
    },
    // UPDATE ITEM DETAIL
    onItemDetailUpdated (updated) {
      const item = _.find(this.$refs.tableTemplatedItems.localItems, { id: updated.item })
      if (item) {
        const itemDetail = _.find(item.item_details, { id: updated.id })
        if (itemDetail) {
          itemDetail.item_detail_name = updated.item_detail_name
          itemDetail.type_id = updated.type
          itemDetail.type_name = updated.type_name
          itemDetail.category_id = updated.category
          itemDetail.category_name = updated.category_name
          itemDetail.supplier_id = updated.supplier
          itemDetail.supplier_name = updated.supplier_name
          itemDetail.brand_id = updated.brand_id
          itemDetail.brand_name = updated.brand_name
          itemDetail.serial_number = updated.serial_number
          itemDetail.purchase_date = updated.purchase_date
          itemDetail.original_cost = updated.original_cost
          itemDetail.specifications = updated.specifications
          itemDetail.is_parent = 0
          itemDetail.active = updated.active
        }
      }
    },
    onRemoveComposition (position) {
      // this.templatedItem.compositions = this.templatedItem.compositions.filter(
      //   (_filter, key) => key !== position
      // )
    }
  }
}
</script>
<style>
  #modal-templated-item .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }
  #modal-templated-item .modal-content {
    min-height: 100vh;
  }
</style>
